import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Snackbar from "@material-ui/core/Snackbar"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import { colors } from '../../styles/colors'

import React, { useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ExclusiveCodes, ExclusivesData, Rewards } from "./ExclusivesAndRewards"
import { GetCountries as getCountries } from "./CountryRegionMap"

type Props = {
    data: UserOrderData
    onUpdatePlatform: (index: number, platform: Platform, country: string) => void
}

export type Platform = 'Steam' | 'Switch' | 'Xbox' | 'Playstation'

export type UserOrderData = {
    order: {
        email: string,
        pid: string,
        platforms: Platform[],
        releaseKeys?: (string | null)[]
        countries?: (string | null)[]
        reward: number
        codes: Partial<{
            [key in ExclusiveCodes]: string
        }>,
        ostKey?: string,
        cookbook?: string,
        coloringBook?: string,
        wallpapers?: string
    }
    keys: string[]
}

const CopyToClipboard = (inputElement: HTMLInputElement, success: () => void) => {
    try {
        inputElement.select()
        inputElement.setSelectionRange(0, 99999) /* For mobile devices */
        document.execCommand("copy")
        success();
    } catch (e) {
        console.log(e);
    }
}

const needCountry = (p: Platform) => p == "Switch" || p == 'Playstation';

const getDigitalRewardsURL = (asset: string) => `https://firebasestorage.googleapis.com/v0/b/roots-of-pacha-pledgebox.appspot.com/o/digital-rewards%2FRoots of Pacha - Kickstarter - ${asset}?alt=media`

// const getWallpaperObject = (index: number) => ({
//     original: `https://firebasestorage.googleapis.com/v0/b/roots-of-pacha-pledgebox.appspot.com/o/digital-rewards%2Fwallpapers%2FRoP_Wallpaper_3840x2160_00${index}.png?alt=media`,
//     thumbnail: `https://firebasestorage.googleapis.com/v0/b/roots-of-pacha-pledgebox.appspot.com/o/digital-rewards%2Fwallpapers%2FRoP_Wallpaper_3840x2160_00${index}.png?alt=media`
// })

// const wallpaperURLs = [
//     getWallpaperObject(1),
//     getWallpaperObject(2),
//     getWallpaperObject(3),
//     getWallpaperObject(4),
//     getWallpaperObject(5),
//     getWallpaperObject(6),
//     getWallpaperObject(7),
//     getWallpaperObject(8)
// ]

export const UserOrder: React.FC<Props> = ({ data, onUpdatePlatform }) => {
    const SHOW_EXCLUSIVES = true
    const XBOX_RELEASED = true

    const [copySnackOpen, setCopySnackOpen] = useState(false);
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    let releaseKeys = data.order.releaseKeys || []
    let keyCountries = data.order.countries || []
    let codes = data.order.codes

    let hasAnySteamKey = false;
    let hasAnyNXKey = false;
    let hasAnyPSKey = false;
    let hasAnyXboxKey = false;

    let platformsWithKeys = data.order.platforms.map((platform, index) => {

        const isSteam = platform == 'Steam';
        hasAnySteamKey = hasAnySteamKey || isSteam;

        const isNX = platform == 'Switch';
        hasAnyNXKey = hasAnyNXKey || isNX;

        const isPS = platform == 'Playstation';
        hasAnyPSKey = hasAnyPSKey || isPS;

        const isXbox = platform == 'Xbox';
        hasAnyXboxKey = hasAnyXboxKey || isXbox;

        const releaseKey = index < releaseKeys.length ? releaseKeys[index] : null
        const country = index < keyCountries.length ? keyCountries[index] : null
        const hasCountry = country != null && country != "";
        const isAssigned = releaseKey != null

        const isXboxNotReleased = isXbox && !XBOX_RELEASED;
        let key = isXboxNotReleased ? 'Check back on July 31st'
            : needCountry(platform)
                ? hasCountry
                    ? (releaseKey || 'Country assigned. Please reach out to support@rootsofpacha.com to continue')
                    : 'Please select a country'
                : (releaseKey || "You should have a key assigned. Please reach out to support@rootsofpacha.com")

        return {
            index,
            platform,
            key,
            country,
            isAssigned
        }
    })

    let exclusives: ExclusiveCodes[] = []
    for (let e of Object.keys(ExclusivesData)) {
        let code: ExclusiveCodes = e as ExclusiveCodes
        if (ExclusivesData[code].rewards.includes(data.order.reward)) {
            exclusives.push(code)
        }
    }

    let { ostKey, pid } = data.order
    
    let pidParsed = parseInt(pid)
    if (isNaN(pidParsed)) pidParsed = -1
    
    let hasCookbook = Rewards.hasCookbook(data.order.reward, pidParsed)
        ? true
        : false

    let hasColoringBook = Rewards.hasColoringBook(data.order.reward, pidParsed)
        ? true
        : false

    let hasWallpapers = Rewards.hasWallpapers(data.order.reward)
        ? true
        : false

    let ostTextfieldRef = React.createRef<HTMLInputElement>()

    let hasOtherDigitalRewards = ostKey || hasCookbook || hasColoringBook || hasWallpapers

    return <React.Fragment>
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={copySnackOpen}
            onClose={() => setCopySnackOpen(false)}
            autoHideDuration={2000}
            message='Key copied to Clipboard'
        />
        <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={2}>
                    <Typography variant='h5'>Your data</Typography>
                </Box>
                <TextField InputProps={{ readOnly: true, }} label={`Email`} size='small' fullWidth variant='outlined' value={data.order.email} />
            </Paper>
        </Box>
        <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={2}>
                    <Typography variant='h5'>Your keys</Typography>
                </Box>
                <Grid container spacing={2}>
                    {
                        platformsWithKeys.map((x, i) => {
                            const [openConfirmationToSteam, setOpenConfirmationToSteam] = React.useState(false);
                            const [openConfirmationToAny, setOpenConfirmationToAny] = React.useState<[boolean, Platform | null]>([false, null]);
                            const [openConfirmationCountry, setOpenConfirmationCountry] = React.useState<[boolean, { c: string, r: string } | null]>([false, null]);

                            const handleChangeToSteam = () => {
                                setOpenConfirmationToSteam(true);
                            };

                            const handleCloseToSteam = () => {
                                setOpenConfirmationToSteam(false);
                            };

                            const handleCloseToSteamAndApply = () => {
                                setOpenConfirmationToSteam(false);
                                onUpdatePlatform(i, 'Steam', x.country ? x.country : '')
                            };

                            const handleChangePlatformToAny = (p: Platform) => {
                                setOpenConfirmationToAny([true, p]);

                            };
                            const handleCloseToAny = () => {
                                setOpenConfirmationToAny([false, null]);
                            };

                            const handleCloseToAnyAndApply = () => {
                                onUpdatePlatform(i, openConfirmationToAny[1]!, x.country ? x.country : '')
                                setOpenConfirmationToAny([false, null]);
                            };

                            const handleChangeCountry = (c: string, r: string) => {
                                setOpenConfirmationCountry([true, { c, r }]);
                            }

                            const handleApplyCountry = () => {
                                if (openConfirmationCountry[1] != null) {
                                    onUpdatePlatform(i, x.platform, openConfirmationCountry[1].c)
                                }
                                setOpenConfirmationToAny([false, null]);
                            }

                            const handleCloseCountry = () => {
                                setOpenConfirmationCountry([false, null]);
                            }


                            var hasCountry = x.country != null && x.country != "";

                            let textfieldRef = React.createRef<HTMLInputElement>()
                            return <Grid key={`key-${i}`} item xs={12}>
                                <ChangeToSteamDialog open={openConfirmationToSteam}
                                    fromPlatform={x.platform}
                                    toPlatform="Steam"
                                    onClose={handleCloseToSteam}
                                    onApply={handleCloseToSteamAndApply} />
                                <ChangeToAnyPlatformDialog open={openConfirmationToAny[0]}
                                    fromPlatform={x.platform}
                                    toPlatform={openConfirmationToAny[1]}
                                    onClose={handleCloseToAny}
                                    onApply={handleCloseToAnyAndApply} />
                                <CountrySelectDialog open={openConfirmationCountry[0]}
                                    data={openConfirmationCountry[1]}
                                    onApply={handleApplyCountry}
                                    onClose={handleCloseCountry} />
                                <Paper elevation={1} style={{ marginBottom: 8, padding: '0px 8px' }}>
                                    <Grid alignItems='center' container spacing={1}>
                                        <Grid item>
                                            <Typography variant="h6" style={{
                                                alignSelf: 'center'
                                            }}>
                                                {`#${i + 1}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={1} style={{ minWidth: 144 }}>
                                            <PlatformControl platform={x.platform}
                                                disabled={x.platform == 'Steam' || x.platform == 'Xbox' || hasCountry}
                                                handleChangeToSteam={handleChangeToSteam}
                                                handleChangePlatformToAny={handleChangePlatformToAny} />
                                        </Grid>
                                        {
                                            needCountry(x.platform) && !hasCountry &&
                                            <Grid item xs={12} sm={1} style={{ minWidth: 144 }}>
                                                <CountryControl platform={x.platform}
                                                    hasCountry={hasCountry}
                                                    country={x.country}
                                                    handleSelect={handleChangeCountry} />
                                            </Grid>
                                        }
                                        <Grid xs={x.isAssigned ? 9 : 12} sm item>
                                            <TextField
                                                inputRef={textfieldRef}
                                                InputProps={{
                                                    readOnly: true,
                                                }} label={hasCountry ? `Key (${x.country})` : `Key`} size='small' fullWidth variant='outlined' value={x.key} />
                                        </Grid>
                                        {
                                            x.isAssigned && <Grid xs={3} sm={1} item>
                                                <Button fullWidth onClick={() => textfieldRef.current && CopyToClipboard(textfieldRef.current, () => setCopySnackOpen(true))}>Copy</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>;
                        })
                    }
                </Grid>
                <HowToRedemKey nx={hasAnyNXKey} ps={hasAnyPSKey} steam={hasAnySteamKey} xbox={hasAnyXboxKey} />
            </Paper>
        </Box >
        {hasOtherDigitalRewards && <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={2}>
                    <Typography variant='h5'>Other digital rewards</Typography>
                </Box>
                {
                    <Paper elevation={1} style={{
                        marginBottom: 8,
                    }}>
                        {ostKey &&
                            <Box display='flex' flexDirection='row' justifyContent='stretch' alignItems={'center'} flexWrap={'wrap'} p={1}>
                                <Box display={'flex'} minWidth={250} mr={2}>
                                    <Typography variant="h6" style={{ alignSelf: 'center' }}>
                                        OST (Steam)
                                    </Typography>
                                </Box>
                                <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'} mr={1}>
                                    <TextField
                                        inputRef={ostTextfieldRef}
                                        InputProps={{ readOnly: true }} label={`Code`} size='small' fullWidth variant='outlined' value={ostKey} />
                                </Box>
                                {
                                    <Button fullWidth={smallDevice} onClick={() => ostTextfieldRef.current && CopyToClipboard(ostTextfieldRef.current, () => setCopySnackOpen(true))}>Copy</Button>
                                }
                            </Box>
                        }
                        {hasWallpapers &&
                            <Box display='flex' flexDirection='row' justifyContent='stretch' alignItems={'center'} flexWrap={'wrap'} p={1}>
                                <Box display={'flex'} minWidth={250} mr={2}>
                                    <Typography variant="h6" style={{
                                        alignSelf: 'center'
                                    }}>
                                        Wallpapers
                                    </Typography>
                                </Box>
                                <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'} mr={1}>
                                    <Button variant="contained" fullWidth={true} onClick={() => window.open(getDigitalRewardsURL('Wallpapers.zip'))}>Download ZIP</Button>
                                </Box>
                            </Box>
                        }
                        {hasColoringBook &&
                            <Box display='flex' flexDirection='row' justifyContent='stretch' alignItems={'center'} flexWrap={'wrap'} p={1}>
                                <Box display={'flex'} minWidth={250} mr={2}>
                                    <Typography variant="h6" style={{
                                        alignSelf: 'center'
                                    }}>
                                        Coloring Book
                                    </Typography>
                                </Box>
                                <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'} mr={1}>
                                    <Button variant="contained" fullWidth={true} onClick={() => window.open(getDigitalRewardsURL('Coloring Book.pdf'))}>Download</Button>
                                </Box>
                            </Box>
                        }
                        {hasCookbook &&
                            <Box display='flex' flexDirection='row' justifyContent='stretch' alignItems={'center'} flexWrap={'wrap'} p={1}>
                                <Box display={'flex'} minWidth={250} mr={2}>
                                    <Typography variant="h6" style={{
                                        alignSelf: 'center'
                                    }}>
                                        Cookbook
                                    </Typography>
                                </Box>
                                <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'} mb={smallDevice ? 1 : 0} mr={1}>
                                    <Button variant="contained" fullWidth={true} onClick={() => window.open(getDigitalRewardsURL('Cookbook.pdf'))}>Download</Button>
                                </Box>
                                <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'}  mr={1}>
                                    <Button variant="contained" fullWidth={true} onClick={() => window.open(getDigitalRewardsURL('Cookbook[Print].pdf'))}>Download for Print</Button>
                                </Box>
                            </Box>
                        }
                    </Paper>
                }
            </Paper>
        </Box >
        }
        {SHOW_EXCLUSIVES && <Box mb={2}>
            <Paper style={{ padding: 16 }} variant='outlined'>
                <Box mb={2}>
                    <Typography variant='h5'>Your In-Game Exclusives</Typography>
                </Box>
                {
                    (codes != undefined) && exclusives
                        .map((x, i) => {

                            let data = ExclusivesData[x]

                            let displayedCode = codes[x]
                            if (displayedCode) {
                                displayedCode = `${displayedCode.substring(0, 4)}-${displayedCode.substring(4, 8)}-${displayedCode.substring(8, 12)}`
                            }

                            let textfieldRef = React.createRef<HTMLInputElement>()
                            return <div key={`key-${i}`}>
                                <Paper elevation={1} style={{
                                    marginBottom: 8,
                                }}>
                                    <Box display='flex' flexDirection='row' justifyContent='stretch' alignItems={'center'} flexWrap={'wrap'} p={1}>
                                        <Box display={'flex'} minWidth={250} mr={2}>
                                            <Typography variant="h6" style={{
                                                alignSelf: 'center'
                                            }}>
                                                {data.label}
                                            </Typography>
                                        </Box>
                                        <Box flex={10} minWidth={smallDevice ? '100%' : 120} justifyContent={'center'} mr={1}>
                                            <TextField
                                                inputRef={textfieldRef}
                                                InputProps={{
                                                    readOnly: true,
                                                }} label={`Code`} size='small' fullWidth variant='outlined' value={displayedCode || 'You should have a code assigned. Contact support@rootsofpacha.com'} />
                                        </Box>
                                        {
                                            displayedCode && <Button fullWidth={smallDevice} onClick={() => textfieldRef.current && CopyToClipboard(textfieldRef.current, () => setCopySnackOpen(true))}>Copy</Button>
                                        }
                                    </Box>
                                </Paper>
                            </div>;
                        })
                }
                {
                    <Box mb={2}>
                        <Button fullWidth={true} style={{ marginTop: 16 }} variant="contained" href={"https://www.kickstarter.com/projects/sodaden/roots-of-pacha/posts/3792180/"} target="_blank ">
                            How to use the codes in-game
                        </Button>
                    </Box>
                }
            </Paper>
        </Box >
        }
        {
            <Box mb={2}>
                <Typography style={{ textAlign: 'center', color: colors.logoPurple }} variant='h6'>Have fun ❤</Typography>
            </Box>
        }
    </React.Fragment >
}

type ChangeDialogProps = {
    open: boolean,
    fromPlatform: Platform | null,
    toPlatform: Platform | null
    onClose: () => void
    onApply: () => void
}

const ChangeToSteamDialog: React.FC<ChangeDialogProps> = (props) => {
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Change to Steam"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You're about to change the copy of this platform from {props.fromPlatform} to Steam. This will issue a Steam key and you won't be able to select another platform after this. Do you still want to change the platform to Steam?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Don't change</Button>
            <Button variant="contained" color="primary" onClick={props.onApply}>Change to Steam</Button>
        </DialogActions>
    </Dialog>
}

const ChangeToAnyPlatformDialog: React.FC<ChangeDialogProps> = (props) => {
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Change platform"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Do you want to change the copy of this platform from {props.fromPlatform} to {props.toPlatform}?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Don't change</Button>
            <Button variant="contained" color="primary" onClick={props.onApply}>Change to {props.toPlatform}</Button>
        </DialogActions>
    </Dialog>
}

type CountrySelectProps = {
    open: boolean,
    data: { c: string, r: string } | null,
    onClose: () => void
    onApply: () => void
}

const CountrySelectDialog: React.FC<CountrySelectProps> = (props) => {
    var country = props.data ? props.data.c : '';
    var region = props.data ? props.data.r : '';
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Country and Region Selection
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                You've selected <b>{country} [<em>{region}</em>]</b>. Your key will be locked after this selection and you won't be able to change it.<br />
                Please confirm if you want to continue?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button variant="contained" color="primary" onClick={props.onApply}>Confirm</Button>
        </DialogActions>
    </Dialog>
}

type PlatformControlProps = {
    disabled: boolean,
    platform: Platform,
    handleChangeToSteam: () => void
    handleChangePlatformToAny: (p: Platform) => void
}

const PlatformControl: React.FC<PlatformControlProps> = (props) => {
    return <FormControl size='small' variant='outlined' fullWidth={true} disabled={props.disabled}>
        <InputLabel shrink htmlFor="select">
            Platform
        </InputLabel>
        <Select label="Platform" inputProps={{
            id: 'select',
        }} fullWidth={true} value={props.platform} onChange={(p) => {
            var newPlatform = p.target.value as Platform
            if (newPlatform == 'Steam') {
                props.handleChangeToSteam()
            } else {
                props.handleChangePlatformToAny(p.target.value as Platform)
            }
        }}>
            <MenuItem value={'Steam'}>Steam</MenuItem>
            <MenuItem value={'Switch'}>Switch</MenuItem>
            <MenuItem value={'Playstation'}>Playstation</MenuItem>
            <MenuItem value={'Xbox'}>Xbox</MenuItem>
        </Select>
    </FormControl>
}

type CountryControlProps = {
    country: string | null,
    hasCountry: boolean,
    platform: Platform,
    handleSelect: (country: string, region: string) => void
}
const CountryControl: React.FC<CountryControlProps> = (props) => {
    return <FormControl size='small' variant='outlined' fullWidth={true} disabled={props.hasCountry}>
        <InputLabel htmlFor="select">Country</InputLabel>
        <Select label="Country" inputProps={{
            id: 'select',
        }} fullWidth={true} value={props.country != null ? props.country : ''} onChange={(p) => {
            var selectedCountry = p.target.value as string
            var selectedData = getCountries(props.platform).find(x => x.country == selectedCountry);
            var region = selectedData ? selectedData.region : undefined;
            if (region) {
                props.handleSelect(selectedCountry, region)
            }
        }}>
            {
                getCountries(props.platform).map(x => {
                    return <MenuItem key={x.country} value={x.country}>{x.country}</MenuItem>
                })
            }
        </Select>
    </FormControl>
}

type HowToRedemKeyProps = {
    steam: boolean
    nx: boolean
    ps: boolean
    xbox: boolean
}

const HowToRedemKey: React.FC<HowToRedemKeyProps> = (props) => {
    const { nx, ps, steam } = props
    return (nx || ps || steam) && <Grid container style={{ marginTop: 16 }} spacing={1}>
        <Grid item xs={12}>
            <Typography variant='button'>How to activate your keys?</Typography>
        </Grid>
        {
            steam && <Grid item xs={12} sm>
                <Button fullWidth variant="contained" href={"https://help.steampowered.com/en/faqs/view/2A12-9D79-C3D7-F870"} target="_blank ">
                    Steam
                </Button>
            </Grid>
        }
        {
            nx && <Grid item xs={12} sm>
                <Button fullWidth variant="contained" href={"https://en-americas-support.nintendo.com/app/answers/detail/a_id/22429/~/how-to-redeem-a-download-code-on-nintendo-switch-eshop"} target="_blank ">
                    Nintendo Switch
                </Button>
            </Grid>
        }
        {
            ps && <Grid item xs={12} sm>
                <Button fullWidth variant="contained" href={"https://www.playstation.com/en-us/support/store/redeem-ps-store-voucher-code/"} target="_blank ">
                    PlayStation
                </Button>
            </Grid>
        }
        {
            false && <Grid item xs={12} sm>
                <Button fullWidth variant="contained" href={"https://support.xbox.com/en-US/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes"} target="_blank ">
                    Xbox
                </Button>
            </Grid>
        }
    </Grid> || null
}